<template>
  <base-bodytext :text="item.body" />
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'

export default {
  components: {
    BaseBodytext
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.component {
  &:not(:last-of-type) {
    padding-bottom: calc(var(--blank-line) * 2);
  }

  @media (min-width: $medium) {
    &:not(:last-of-type) {
      padding-bottom: 0;
    }
  }
}
</style>
